<template>
<Header :cartProductNumber="cartProductNumber" />
        
    
    <section class="body-section c">
        <Sidebar />

        <div class="content-container">
            <div class="Main-container">
                <div class="Product-section">
                    <div class="product-section-container">
                        <div class="separator2"></div>
                        
                        <div  v-if="gettingcategories_loading">
                            <div class="spinner-border" role="status" style="margin-left: 50%;">
                                <span class="sr-only">Loading...</span>
                            </div> 
                        </div>
                        <div v-else>
                            <div class="product-heading">
                                <h1>Categories</h1>
                            </div>
                            <div class="categories-container">
                                <div v-for="(item, index) in category" :key="index">   
                                    <div>                           
                                        <div>
                                            <p @click="productDetails(item)" class="categoryname" to="/Products">{{item.category_name}}</p>
                                        </div>
                                        <img :src="'https://admin.emart.rw/img/'+item.cover_photo" alt="">  
                                    </div> 
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="separated"></div>
            </div>
  
         <Footer/>
        </div>
    </section>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import Sidebar from "@/components/Sidebar.vue"
import axios from 'axios'
export default {
    name: 'Categories',
    components:{
        Header,Sidebar,Footer
    },
    data(){
        return{
            gettingcategories_loading:true,
            category:[],
            

            gettingcategories_loading:true,
            cartProductNumber:0,

        }
    },
    created(){
        this.getCartProductNumber();
        this.getCategoryWithproducts();
    },
    
    methods:{
        getCartProductNumber(){
            let NumberProduct = localStorage.getItem('@cart');
            NumberProduct = JSON.parse(NumberProduct);
            if (NumberProduct == null) {}else{
                this.cartProductNumber = NumberProduct.length;
            }
            
        },
        
         productDetails(item){
            let clean_product_name = item.category_name.replaceAll("/","-");
            localStorage.setItem("ProductDetail", JSON.stringify(item));
            this.$router.push(`/Products/${clean_product_name}`)
        },
        getCategoryWithproducts(){
            axios.get('https://admin.emart.rw/api/product_category_web/54')
            .then((result) => {
                if(result.data){
                    this.gettingcategories_loading = false;
                    this.category = result.data;

                }
            })
            .catch((error) => {
                //console.log(error);
                this.gettingcategories = false;
            });
        },
    },
    mounted() {  
    document.title = "Categories";  
  }, 
}
</script>