<template>
	
   <Header :cartProductNumber="cartProductNumber" />

    <section class="body-section">
    	<Sidebar/>
        <div class="content-container">
            <div class="loginContainer">
                <h1>Verify</h1>
                <p>please enter the code you received either from your email or phone number </p>
                <div class="otp">
                    <input type="text" v-model="userotp">
                </div>
                <button @click="checkOTP()">Next</button>
            </div>
            <h1 class="signmsg">If you want to change your Number click <router-link to="/Login">Go Back</router-link></h1>
            
            <div class="separated"></div>
         <Footer/>
        </div>
    </section>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
export default{
  name: 'opt',
    components:{
        Header,Sidebar,Footer
    }, 
    data(){
        return {

            gettingcategories_loading:true,
            category_with_products:[],
            cartProductNumber:0,
            otp:'',
            userotp:'',
        }
    },
    mounted() {  
        document.title = "Login Verification";  
    }, 
    created(){
        this.getCartProductNumber();
        this.getOTP();
    },
    methods:{
        getOTP(){
            let otp = localStorage.getItem('@otp');
            otp = JSON.parse(otp);
            if (otp == null) {
                this.$router.push(`/Login`) 
            }else{
                this.otp = otp.otp
            }

        },
        checkOTP(){
            if (this.userotp == this.otp) {
                this.$router.push(`/Register`) 
            }else{
                alert('Dear Client,\nThe otp you have provided is invalid, please try again later');

            }
        },
        getCartProductNumber(){
            let NumberProduct = localStorage.getItem('@cart');
            NumberProduct = JSON.parse(NumberProduct);
            if (NumberProduct == null) {}else{
                this.cartProductNumber = NumberProduct.length;
            }
            
        },
    },
}
</script>