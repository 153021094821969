<template>
    <div class="footer">
        <h1>Copyright © 2022 eMart</h1>
    </div>
</template>
<script>

export default {
    name: 'Footer',
}
</script>