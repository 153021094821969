<template>
	
   <Header :cartProductNumber="cartProductNumber" />

    <div class="popup" v-if="popUpShow">
        <h1>INFO</h1>
        <p>{{popUpMessage}}</p>
        <div class="popupbtn2">
            <!-- <button class="btncanc">Cancel</button> -->
            <button class="btncon" @click="okFunction()">OK</button>
        </div>
    </div>
    <section class="body-section">
    	<Sidebar/>
        <div class="content-container">
            <div class="loginContainer">
                <h1>Sign in Information</h1>
                <p>Please enter your phone number to sign in</p>
                <select v-model="selected" @change="countryCodeSelected(selected)">
                    <option v-for="(allcountriesinfo, countrieIndex) in allcountries" :key="countrieIndex" v-bind:value="allcountriesinfo.dial_code">
                        {{allcountriesinfo.flag}} {{allcountriesinfo.name}}({{allcountriesinfo.dial_code}})
                    </option>
                </select>
                <input name="phone" type="text" placeholder="Phone Number" v-model="phoneNumber"/>
                <!-- <vue-tel-input v-model="phone"></vue-tel-input> -->
                <!-- <template></template> -->

                <div v-if="loginLoading">
                    <div class="spinner-border" role="status" style="margin-left: 50%;">
                      <span class="sr-only">Loading...</span>
                    </div> 
                </div>
                <button @click="goToOtp()" v-else>Next</button>
            </div>
            <div class="separated"></div>
         <Footer/>
        </div>
    </section>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import axios from 'axios'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default{
    name: 'Login',
    components:{
        Header,
        Sidebar,
        Footer,
        VueTelInput,
    }, 
    data(){
        return {
            gettingcategories_loading:true,
            category_with_products:[],
            allcountries:[],
            cartProductNumber:0,
            phoneNumber:'+250',
            loginLoading:false,
            phone:'0788',
            value:'',
            selected:'+250',
            popUpShow:false,
        }
    },
    mounted() {  
        document.title = "Login";  
    }, 
    created(){
        this.getCartProductNumber();
        this.getAllCountries();
    },
    methods:{
        countryCodeSelected(selected){
            this.phoneNumber = this.selected;
            // console.log(this.selected);
        },
        okFunction(){
            this.popUpShow = false;
        },
        getAllCountries(){

                axios.get('https://gist.githubusercontent.com/DmytroLisitsyn/1c31186e5b66f1d6c52da6b5c70b12ad/raw/2bc71083a77106afec2ec37cf49d05ee54be1a22/country_dial_info.json')
                .then((result) => {
                    if(result.data){
                        this.allcountries = result.data;
                        //console.log(result.data);
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                });
        },
        getCartProductNumber(){
            let NumberProduct = localStorage.getItem('@cart');
            NumberProduct = JSON.parse(NumberProduct);
            if (NumberProduct == null) {}else{
                this.cartProductNumber = NumberProduct.length;
            }
            
        },
        goToOtp(){
            if (this.selected == '' || this.selected == null) {
                return false;
            }
            // console.log(this.selected);
            // return false;
            this.loginLoading = true;
            let cleanedPhoneNumber = this.phoneNumber.replace(/[^\d]/g, '');
            // cleanedPhoneNumber = Number(cleanedPhoneNumber);
            if (cleanedPhoneNumber.length <= 15 && cleanedPhoneNumber.length > 11) {
                cleanedPhoneNumber='+'+cleanedPhoneNumber;
                var javaandroidmsgid = parseInt(10000*Math.random());
                var fourdigitsrandom = Math.floor(1000 + Math.random() * 9000);
                let verficationCode=fourdigitsrandom;

                let userData = {
                    username:cleanedPhoneNumber,
                    password:'password',
                    verification_code:verficationCode,
                } 

                let signUpUserData = {
                    // method:"login",
                    name:'User',
                    username:cleanedPhoneNumber,
                    password:'password',
                    password_confirmation:'password',
                    'verification-code':verficationCode,
                }

                axios.post('https://admin.emart.rw/api/login-website',userData)
                .then((result) => {
                    if(result.data){
                        if (result.data.message) { 
                            axios.post('https://admin.emart.rw/api/register',signUpUserData)
                            .then((result) => {
                                if (result.data) {
                                    if (result.data.error) {
                                        this.loginLoading = false;
                                        alert('Dear Client,\nThe phoneNumber you have used already exists in the system');
                                    }
                                    else{
                                        this.loginLoading = false;
                                        let otpData = {
                                            'pageFrom':'signUp',
                                            'otp':verficationCode
                                        };
                                        localStorage.setItem("@userInfo", JSON.stringify(result.data));
                                        localStorage.setItem("@otp", JSON.stringify(otpData));
                                        this.$router.push(`/otp`)
                                    }
                                }else{

                                }
                            })
                            .catch((error) => {
                                console.log('Error', error);
                            });
                        }
                        else{
                            this.loginLoading = false;
                            localStorage.setItem("@userInfo", JSON.stringify(result.data));
                            let otpData = {
                                'pageFrom':'signIn',
                                'otp':verficationCode
                            };
                            localStorage.setItem("@otp", JSON.stringify(otpData));
                            this.$router.push(`/otp`) 

                        }
                    }
                })
                .catch((error) => {
                    console.log('Error', error);
                });
            }else{
                this.loginLoading = false;
                // alert('Dear Client,\nThe number you have entered is invalid');


                this.popUpShow = true;                 
                this.popUpMessage = 'Dear Client,\nThe number you have entered is invalid';
            }
            return false;

            let productData = {
                productId:this.productInformation.productId,
                category:this.productInformation.productCategory,
                categoryId:this.productInformation.productCategoryId,
                clientId:54,
                userId:48,
            }
        },
        isNumberKey(evt){
              var charCode = (evt.which) ? evt.which : event.keyCode
              if (!(charCode>=48 && charCode<=57 || charCode==8 || charCode==46))
                 return false;

              return true;
        },


        isAlphaKey(evt)//-------------------------Enabling Only Characters(a-z,A-Z) , ('&.), Delete & Backspace
        {
              var key = (evt.which) ? evt.which : event.keyCode
              if (!((key == 97) || (key == 98) || (key == 39) || (key ==46) || (key == 99) || (key == 8) || (key>=65) && (key <= 90) || (key==32) || (key >= 97 && key <= 122)))
                 return false;

              return true;
        },

        isNationalId(evt)//-------------------------Enabling Only Numbers(48-57) , Delete & Backspace
        {
              var national_id = document.getElementById('national_id').value;
              if (national_id.length > 15) {
                 return false;
              }
              var charCode = (evt.which) ? evt.which : event.keyCode
              if (!(charCode>=48 && charCode<=57 || charCode==8 || charCode==46))
                 return false;

              return true;
        },
    },
}
</script>