<template>
    <Header :cartProductNumber="cartProductNumber" />
    <div class="popup" v-if="popUpShow">
            <h1>THANK YOU!</h1>
            <p>{{popUpMessage}}</p>
            <div class="popupbtn2">
                <!-- <button class="btncanc">Cancel</button> -->
                <button class="btncon" @click="okFunction()">OK</button>
            </div>
    </div>
    <div class="popup" v-if="popUpShow2">
            <h1>INFO</h1>
            <p>{{popUpMessage}}</p>
            <div class="popupbtn2">
                <!-- <button class="btncanc">Cancel</button> -->
                <button class="btncon" @click="okFunctionclose()">OK</button>
            </div>
    </div>

    <div class="popup" v-if="popUpPromoCode">
            <h1>PROMO CODE INFO</h1>
            <h1>{{promocode_percentage}}</h1>
            <p>{{popUpMessage}}</p>
            <div class="popupbtn2">
                <!-- <button class="btncanc">Cancel</button> -->
                <button class="btncon" @click="okPromoCodeClose()">OK</button>
            </div>
    </div>
    <section class="body-section">
        <Sidebar />
        <div class="content-container">
            <div class="Main-container">
                <div class="cartContainer">
                    <h1 class="checkout-h1">
                        <a href="cart.html">
                            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.93934 21.0607C10.5251 21.6464 11.4749 21.6464 12.0607 21.0607C12.6464 20.4749 12.6464 19.5251 12.0607 18.9393L9.93934 21.0607ZM2 11L0.93934 9.93934C0.353553 10.5251 0.353553 11.4749 0.93934 12.0607L2 11ZM12.0607 3.06066C12.6464 2.47487 12.6464 1.52513 12.0607 0.93934C11.4749 0.353553 10.5251 0.353553 9.93934 0.93934L12.0607 3.06066ZM12.0607 18.9393L3.06066 9.93934L0.93934 12.0607L9.93934 21.0607L12.0607 18.9393ZM3.06066 12.0607L12.0607 3.06066L9.93934 0.93934L0.93934 9.93934L3.06066 12.0607Z" fill="#0B4F70"/>
                            </svg>&nbsp;
                            GO BACK TO CART
                        </a>
                        <div v-if="popUpPromoCode_available">
                            <span style="float:right;color:var(--textcolor)" v-if="this.cartTotalPrice >= 10000">
                                <span style="text-decoration: line-through;">TOTAL AMOUNT : RWF {{ this.formatNumberWithCommas(this.cartTotalPrice) }}</span><br/>
                                <span>TOTAL AMOUNT : RWF {{ this.formatNumberWithCommas(this.promotion_cartTotalPrice) }}</span>
                            </span>
                            <span style="float:right;color:var(--textcolor)" v-else>
                                <span style="text-decoration: line-through;">TOTAL AMOUNT : RWF {{ this.formatNumberWithCommas(this.cartTotalPrice) }}</span><br/>
                                <span>TOTAL AMOUNT : RWF {{ this.formatNumberWithCommas(this.promotion_cartTotalPrice) }}</span>
                            </span>                        
                        </div>
                        <div v-else>
                            <span style="float:right;color:var(--textcolor)" v-if="this.cartTotalPrice >= 10000">
                                TOTAL AMOUNT : RWF {{ this.formatNumberWithCommas(this.cartTotalPrice) }}
                            </span>
                            <span style="float:right;color:var(--textcolor)" v-else>
                                TOTAL AMOUNT : RWF {{this.formatNumberWithCommas(this.cartTotalPrice)}} + {{this.formatNumberWithCommas(this.selectedDeliveryPrice)}} FRW
                            </span>    
                        </div>
                    </h1>
                    
                    <div class="checkoutcardContainer">
                        <div class="checkoutCard address">
                            <div class="checkoutHeader home">
                                <div class="circle-icon">
                                    <div></div>
                                </div>
                                <h1>Delivery Address</h1>
                                <button class="edit-btn disabled-btn" onclick="openbodyaddress()">Edit</button>
                            </div>
                            <div class="checkoutBody checkoutactive">
                                <div class="separator4"></div>
                                <!-- <button onclick="currentactive()" class="currentbtn">Current Address</button>
                                <button onclick="homeactive()" class="homebtn">Home Address</button>
                                <button onclick="mapactive()" class="mapbtn">Use Map</button> -->
                                <div class="current checkoutactive">
                                    <!-- <div class="form-group">
                                        <label>Delivery Location</label>
                                        <input type="text"  name="" class="input" id="search_input" placeholder="Type address...">
                                    </div> -->

                                    <div class="form-group">
                                        <label>Address</label>
                                        <input type="text"  name="" class="input" placeholder="Delivery Address" id="" v-model="clientInfo.clientAddress">
                                    </div>
                                    <div class="form-group">
                                        <label>Phone number to call</label>
                                        <input type="text"  name="" class="input" placeholder="078 ****" id="" v-model="clientInfo.clientPhoneNo">
                                    </div><br>
                                    <!-- <div class="form-group">
                                        <label>House Number</label>
                                        <input type="text"  name="" class="input" v-model="clientInfo.clientPhoneNo" id="">
                                    </div> -->
                                </div><!-- 
                                <div class="homec checkoutform">
                                    <div class="form-group">
                                        <label>Home Address</label>
                                        <input type="text"  name="" class="input" value="Bumbogo Near azam" id="">
                                    </div>
                                    <div class="form-group">
                                        <label>Phone number to call</label>
                                        <input type="text"  name="" class="input" value="0784258548" id="">
                                    </div>
                                </div>
                                <div class="map-loca checkoutform">
                                    <p style="color:black">This is the map location section</p>
                                </div> -->
                                <div class="separator6"></div>
                                <span class="nextbutton" onclick="timenext()">Next</span>
                                <div class="separator6"></div>
                            </div>
                        </div>
                        <div class="checkoutCard time">
                            <div class="checkoutHeader">
                                <div class="circle-icon">
                                    <div></div>
                                </div>
                                <h1>Delivery Time</h1>
                                <button class="edit-btn" onclick="openbodytime()">Edit</button>
                            </div>
                            <div class="checkoutBody">
                                <div class="current">

                                    <div class="form-check" v-for="(allDeliveryTimeinfo, allDeliveryTimeindex) in allDeliveryTime.slice(1,6)" :key="allDeliveryTimeindex">
                                        <div v-if="allDeliveryTimeinfo.range_condition == selectedDeliveryTime">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" @click="selectDeliveryTimeFunc(allDeliveryTimeinfo)" checked>
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                {{allDeliveryTimeinfo.range_condition}} + {{allDeliveryTimeinfo.condition_price}} FRW
                                            </label>
                                        </div>
                                        <div v-else>
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" @click="selectDeliveryTimeFunc(allDeliveryTimeinfo)">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                {{allDeliveryTimeinfo.range_condition}} + {{allDeliveryTimeinfo.condition_price}} FRW
                                            </label>
                                        </div>
                                    <br></div>
                                </div>
                                <div class="separator4"></div>
                                <span class="nextbutton" onclick="notenext()">Next</span>
                                <div class="separator4"></div>
                            </div>
                        </div>
                        <div class="checkoutCard note">
                            <div class="checkoutHeader">    
                                <div class="circle-icon">
                                    <div></div>
                                </div>
                                <h1>Delivery Instruction</h1>
                                <button class="edit-btn" onclick="openbodynote()">Edit</button>
                            </div>
                            <div class="checkoutBody">
                                <div class="form-group">
                                    <label>Delivery Instruction</label>
                                    <textarea name="" class="input2" placeholder="Please leave your Note here" id="" cols="30" rows="10" v-model="deliveryNote"></textarea>
                                </div>
                                
                                <div class="separator4"></div>
                                <span class="nextbutton" onclick="nextcode()">Next</span>
                                <div class="separator4"></div>
                            </div>
                        </div>
                        <div class="checkoutCard code">
                            <div class="checkoutHeader">
                                <div class="circle-icon">
                                    <div></div>
                                </div>
                                <h1>Promo Code</h1>
                                <button class="edit-btn" onclick="openbodycode()">Edit</button>
                            </div>
                            <div class="checkoutBody">
                                <div class="form-group">
                                    <label for="">Promo Code (Optional)</label>
                                    <input type="text" class="input" placeholder="Write your promo code here" v-model="promocode_info">
                                </div>
                                
                                <div class="separator4"></div>
                                <span class="nextbutton" @click="paymentnext_promocode()">Next</span>
                                <div class="separator4"></div>
                            </div>
                        </div>
                        <div class="checkoutCard payment">
                            <div class="checkoutHeader">
                                <div class="circle-icon">
                                    <div></div>
                                </div>
                                <h1>Payment Methods</h1>
                                <button class="edit-btn" onclick="openbodypayment()">Edit</button>
                            </div>
                            <div class="checkoutBody">
                                <div class="current">
                                    
                                    <p class="checp">Please select your payment methods</p>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked="true" @click="changePaymentMethod('Cash On Delivery')">
                                        <img src="/frontend/assets/cash payment.png" alt="">
                                    </div><br>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" @click="changePaymentMethod('MOMO Pay')">
                                        <img src="/frontend/assets/emart Momo2.png" alt="">
                                    </div><br>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" @click="changePaymentMethod('Equity Pay')">
                                        <img src="/frontend/assets/Equity.png" alt="">
                                    </div>
                                    <div  v-if="makeOrderLoading">
                                        <img src="/frontend/assets/loader4.gif">
                                    </div>
                                    <button class="orderbtn" @click="makeAnOrder()" v-else>PLACE ORDER</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separated">
                </div>
            </div>
            
         <Footer/>
        </div>
    </section>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import axios from 'axios'
export default {
    name: 'Checkout',
    components:{
        Header,Sidebar,
    },
    
    data(){
        return {

            gettingcategories_loading:true,
            category_with_products:[],
            cartProductNumber:0,
            allCartProducts:[],
            cartTotalPrice:0,
            promotion_cartTotalPrice:0,
            clientInfo:{},
            userInfo:{},
            allDeliveryTime:[],
            deliveryNote:'',
            selectedDeliveryTime:'3 Hours',
            selectedDeliveryPrice:1000,
            selected_payment_method:'Cash on Delivery',
            promocode_info:'',
            makeOrderLoading:false,
            loading:false,
            
            popUpShow:false,
            popUpShow2:false,
            popUpPromoCode:false,
            popUpPromoCode_available:false,
            popUpMessage:'',
            promocode_percentage:'',
        }
    },

    mounted() {  
        document.title = "Checkout";  
    }, 

    created(){
        this.getCartProductNumber();
        this.getUserInfo();
        this.getDeliveryTime();
    },

    methods:{
        getCartProductNumber(){
            let NumberProduct = localStorage.getItem('@cart');
            NumberProduct = JSON.parse(NumberProduct);
            if (NumberProduct == null) {
                this.$router.push(`/Cart`)
            }else{
                this.cartProductNumber = NumberProduct.length;
                let cartTotal = 0;
                NumberProduct.map((item,index)=>{
                    item.productCode = item.codeNo;
                    item.productPrice = item.unityPrice;
                    item.productQuantity = item.unitQuantity;
                    item.discount = '0%';
                    cartTotal = cartTotal + (Number(item.unityPrice)*Number(item.unitQuantity))
                });
                this.cartTotalPrice = cartTotal;
                this.allCartProducts = NumberProduct;
                // console.log(this.allCartProducts)
            }


            
        },
        okFunction(){
            this.popUpShow = false;
            this.$router.push(`/`)
        },
        okFunctionclose(){
            this.popUpShow2 = false;

        },
        okPromoCodeClose(){
            this.popUpPromoCode = false;
            // Information For open Finish Order View
            $(".code .checkoutBody").removeClass('checkoutactive');
            $(".payment .checkoutBody").addClass('checkoutactive');
            $(".code .edit-btn").removeClass('disabled-btn');
            $(".payment .edit-btn").addClass('disabled-btn');


        },
        getUserInfo(){
            let user = localStorage.getItem('@allClientInfo');
            let allClientInfo = JSON.parse(user);
            this.clientInfo = allClientInfo.client;
            this.userInfo = allClientInfo.user;
            this.getDeliveryTime();
            // console.log(allClientInfo);

        },
        checkSelectedTime(deliveryCondition){
            // if (true) {}
            console.log(checkSelectedTime);
        },
        selectDeliveryTimeFunc(allDeliveryTimeInfo){
            this.selectedDeliveryTime = allDeliveryTimeInfo.range_condition;
            this.selectedDeliveryPrice = allDeliveryTimeInfo.condition_price;
            // console.log(allDeliveryTimeInfo);
        },
        getDeliveryTime(){
            const headers = { 
                "Authorization": "Bearer "+this.userInfo.access_token,
                // "My-Custom-Header": "foobar"
            };
            let deliveryParameter={
                user_id:this.userInfo.user.id,
                user_address:this.clientInfo.clientAddress,
                promocode:'',
                totalPrice:0,
            };
            axios.post('https://admin.emart.rw/api/deliveryUserAddressCode',deliveryParameter,{ headers })
            .then((result) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        this.allDeliveryTime = result.data;
                        this.selectedDeliveryTime = result.data[1].range_condition;
                        this.selectedDeliveryPrice = result.data[1].condition_price;
                        // console.log(this.allDeliveryTime);

                    }
                }else{
                    alert('Dear '+this.clientInfo.clientFname+',\nWe unable to record your information.');
                }
            })
            .catch((error) => {
                // console.log('Error', error);
            });
            
        },
        formatNumberWithCommas(x) {
              return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g , ",");
              // return x.replace(/B(?=(d{3})+(?!d))/g, ",");
        },
        changePaymentMethod(option){
            this.selected_payment_method = option;
            // console.log(option);
        },
        makeAnOrder(){

            this.makeOrderLoading = true;
            this.loading = true;
            if (this.selectedDeliveryTime == '') {
                this.makeOrderLoading = false;
                this.loading = false;
                this.popUpMessage = 'eMart, Please select the delivery time to proceed ...';
                this.popUpShow2 = true;   
                return false
            }
            if (this.selected_payment_method == '') {
                this.makeOrderLoading = false;
                this.loading = false;
                this.popUpMessage = 'eMart, Please select the payment method ...';
                this.popUpShow2 = true;   
                return false
            }
            if (this.clientInfo.clientAddress == '') {
                this.makeOrderLoading = false;
                this.loading = false;
                this.popUpMessage = 'eMart, Please fill the delivery address to proceed ...';
                this.popUpShow2 = true;   
                return false
            }
            const headers = { 
                'Accept': 'application/json',
                'Content-type': 'application/json', 
                "Authorization": "Bearer "+this.userInfo.access_token,
                // "My-Custom-Header": "foobar"
            };
            let orderData={
                // "ordering_user":2+"*"+clientName,
                "ordering_user":this.clientInfo.clientId+"*"+this.clientInfo.clientFname,
                "deriver_address":this.clientInfo.clientAddress,
                "payment_option":1,
                "payment_option_name":'Cash on delivery',
                "mtnTaxId":'',
                "deriveryOption":this.selectedDeliveryTime,
                "deriveryPrice":this.selectedDeliveryPrice,
                "name":this.clientInfo.clientFname,
                "isPrivate":false,
                "allOrderedProduct":this.allCartProducts,
                "totalOrderPrice":this.popUpPromoCode_available?(Number(this.cartTotalPrice)+Number(this.selectedDeliveryPrice)):(Number(this.promotion_cartTotalPrice)+Number(this.selectedDeliveryPrice)),
                "whomToCall":this.clientInfo.clientPhoneNo,
                "promocodeId":null,
                "promocodeType":null,
                "promocodePercentage":null,
                "promocodeValid":null,
                "promocode":this.promocode_info,
                "promocodeOwner":null,

            };
            axios.post('https://admin.emart.rw/api/give-an-order-website',orderData,{ headers })
            .then((result) => {
                if (result.data) {
                    if (result.data.error) {
                        this.makeOrderLoading = false;
                        this.loading = false;
                        this.popUpMessage = 'Server Error, Unable to process your order. please contact support team...';
                        this.popUpShow = true;
                    }else{
                        this.makeOrderLoading = false;
                        this.loading = false;
                        localStorage.removeItem('@cart');
                        this.popUpMessage = 'Dear '+this.clientInfo.clientFname+' your order was succesfully sent to eMart.\n the team will contact you as soon as possible';
                        this.popUpShow = true;
                        // alert('Dear Client,\nYour order has been succesfully been sent to the emart.\n\nThe team will contact you as soon as possible');
                    }
                }
            })
            .catch((error) => {
                console.log('Error', error);
            });
            // console.log(orderData);
        },



        paymentnext_promocode(){  

                const headers = { 
                    "Authorization": "Bearer "+this.userInfo.access_token,
                    // "My-Custom-Header": "foobar"
                };  

                let deliveryParameter = {
                      'user_id':this.userInfo.user.id,
                      'user_address':this.clientInfo.clientAddress,
                      'promocode':this.promocode_info,
                      'totalPrice':this.cartTotalPrice,
                }
                if (this.promocode_info == '' || this.promocode_info == null || this.promocode_info == ' ') {
                    $(".code .checkoutBody").removeClass('checkoutactive');
                    $(".payment .checkoutBody").addClass('checkoutactive');
                    $(".code .edit-btn").removeClass('disabled-btn');
                    $(".payment .edit-btn").addClass('disabled-btn');
                }else{
                    axios.post('https://admin.emart.rw/api/checkPromoCode',deliveryParameter,{ headers })
                    .then((result) => {
                        if (result.data) {
                            //console.log(result.data);
                                this.promocode_percentage = result.data[0].promocodePercentage+'%';
                                this.popUpMessage = result.data[0].promotionMessage;
                                this.popUpPromoCode = true;   
                                if (result.data[0].promocodeValid == 1) {
                                    this.popUpPromoCode_available = true;  
                                    let newPromoPrice = (this.cartTotalPrice * result.data[0].promocodePercentage)/100;
                                    this.promotion_cartTotalPrice = newPromoPrice;

                                }else{
                                    $(".code .checkoutBody").removeClass('checkoutactive');
                                    $(".payment .checkoutBody").addClass('checkoutactive');
                                    $(".code .edit-btn").removeClass('disabled-btn');
                                    $(".payment .edit-btn").addClass('disabled-btn');
                                }
                            // this.popUpPromoCode_available = true;   
                            // return false

                        }else{
                            // alert('Dear '+this.clientInfo.clientFname+',\nWe unable to record your information.');
                        }
                    })
                    .catch((error) => {
                        // console.log('Error', error);
                    });
                }


                //Information For open Finish Order View

                    // $(".code .checkoutBody").removeClass('checkoutactive');
                    // $(".payment .checkoutBody").addClass('checkoutactive');
                    // $(".code .edit-btn").removeClass('disabled-btn');
                    // $(".payment .edit-btn").addClass('disabled-btn');

                    // alert('James Information');
        }
    },
}

</script>