<template>
    <Header  :cartProductNumber="cartProductNumber" />
    
    <section class="body-section">
        <Sidebar />
        <div class="content-container">
            <div class="Main-container">
                <div class="invoice-container">
                    <div class="invoice-sidebar">
                        <div>
                            <h2><router-link to="/Profile"><svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.93934 21.0607C10.5251 21.6464 11.4749 21.6464 12.0607 21.0607C12.6464 20.4749 12.6464 19.5251 12.0607 18.9393L9.93934 21.0607ZM2 11L0.93934 9.93934C0.353553 10.5251 0.353553 11.4749 0.93934 12.0607L2 11ZM12.0607 3.06066C12.6464 2.47487 12.6464 1.52513 12.0607 0.93934C11.4749 0.353553 10.5251 0.353553 9.93934 0.93934L12.0607 3.06066ZM12.0607 18.9393L3.06066 9.93934L0.93934 12.0607L9.93934 21.0607L12.0607 18.9393ZM3.06066 12.0607L12.0607 3.06066L9.93934 0.93934L0.93934 9.93934L3.06066 12.0607Z" fill="#0B4F70"/>
                        </svg>Go back</router-link></h2>
                            <h1>My Transations</h1>
                            <div v-for="(allTransactionsInfo, allTransactionsIndex) in allTransactions.slice(0,10)" :key="allTransactionsIndex">
                                
                                <div class="active-invoice  inv" v-if="allTransactionsInfo.orderId == notification.orderId">
                                    <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1034_7)">
                                            <path d="M28.75 23H46V26.8333H28.75V23Z" fill="#125070"/>
                                            <path d="M28.75 30.6667H46V34.5H28.75V30.6667Z" fill="#125070"/>
                                            <path d="M28.75 38.3333H46V42.1666H28.75V38.3333Z" fill="#125070"/>
                                            <path d="M28.75 46H46V49.8333H28.75V46Z" fill="#125070"/>
                                            <path d="M21.083 15.3333H24.9163V19.1666H21.083V15.3333Z" fill="#125070"/>
                                            <path d="M21.083 23H24.9163V26.8333H21.083V23Z" fill="#125070"/>
                                            <path d="M21.083 30.6667H24.9163V34.5H21.083V30.6667Z" fill="#125070"/>
                                            <path d="M21.083 38.3333H24.9163V42.1666H21.083V38.3333Z" fill="#125070"/>
                                            <path d="M21.083 46H24.9163V49.8333H21.083V46Z" fill="#125070"/>
                                            <path d="M28.75 15.3333V19.1666H45.3483C44.5941 17.9798 44.0193 16.6881 43.6425 15.3333H28.75Z" fill="#125070"/>
                                            <path d="M53.6667 25.3383V61.3333H15.3333V7.66665H43.6617C44.0324 6.31292 44.6008 5.02125 45.3483 3.83331H15.3333C14.3167 3.83331 13.3416 4.23718 12.6228 4.95607C11.9039 5.67496 11.5 6.64998 11.5 7.66665V61.3333C11.5 62.35 11.9039 63.325 12.6228 64.0439C13.3416 64.7628 14.3167 65.1666 15.3333 65.1666H53.6667C54.6833 65.1666 55.6584 64.7628 56.3772 64.0439C57.0961 63.325 57.5 62.35 57.5 61.3333V25.875C56.2039 25.87 54.9144 25.6894 53.6667 25.3383V25.3383Z" fill="#125070"/>
                                            <path d="M57.5003 21.0834C62.7931 21.0834 67.0837 16.7927 67.0837 11.5C67.0837 6.20729 62.7931 1.91669 57.5003 1.91669C52.2076 1.91669 47.917 6.20729 47.917 11.5C47.917 16.7927 52.2076 21.0834 57.5003 21.0834Z" fill="#125070"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1034_7">
                                                <rect width="69" height="69" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div class="invoice-sidebar-content">
                                        <span class="confirmed-order">. 
                                            {{allTransactionsInfo.orderStatus == 'Done'?('Done'):(null)}}
                                            {{allTransactionsInfo.orderStatus == 'NULL' || allTransactionsInfo.orderStatus == null ?('Pending'):(null)}}
                                            {{allTransactionsInfo.orderStatus == 'CANCELED'?('Cancelled'):(null)}}
                                        </span>
                                        <h2>#{{allTransactionsInfo.orderNumber}}</h2>
                                        <h3>Price: RWF {{this.formatNumberWithCommas(allTransactionsInfo.orderTotal)}}</h3>
                                        <p>Delivery Address: {{allTransactionsInfo.orderAddress}}</p>
                                    </div>
                                    
                                </div>

                                <div v-else class="inv"  @click="transactionDetail(allTransactionsInfo)">
                                    <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1034_7)">
                                            <path d="M28.75 23H46V26.8333H28.75V23Z" fill="#125070"/>
                                            <path d="M28.75 30.6667H46V34.5H28.75V30.6667Z" fill="#125070"/>
                                            <path d="M28.75 38.3333H46V42.1666H28.75V38.3333Z" fill="#125070"/>
                                            <path d="M28.75 46H46V49.8333H28.75V46Z" fill="#125070"/>
                                            <path d="M21.083 15.3333H24.9163V19.1666H21.083V15.3333Z" fill="#125070"/>
                                            <path d="M21.083 23H24.9163V26.8333H21.083V23Z" fill="#125070"/>
                                            <path d="M21.083 30.6667H24.9163V34.5H21.083V30.6667Z" fill="#125070"/>
                                            <path d="M21.083 38.3333H24.9163V42.1666H21.083V38.3333Z" fill="#125070"/>
                                            <path d="M21.083 46H24.9163V49.8333H21.083V46Z" fill="#125070"/>
                                            <path d="M28.75 15.3333V19.1666H45.3483C44.5941 17.9798 44.0193 16.6881 43.6425 15.3333H28.75Z" fill="#125070"/>
                                            <path d="M53.6667 25.3383V61.3333H15.3333V7.66665H43.6617C44.0324 6.31292 44.6008 5.02125 45.3483 3.83331H15.3333C14.3167 3.83331 13.3416 4.23718 12.6228 4.95607C11.9039 5.67496 11.5 6.64998 11.5 7.66665V61.3333C11.5 62.35 11.9039 63.325 12.6228 64.0439C13.3416 64.7628 14.3167 65.1666 15.3333 65.1666H53.6667C54.6833 65.1666 55.6584 64.7628 56.3772 64.0439C57.0961 63.325 57.5 62.35 57.5 61.3333V25.875C56.2039 25.87 54.9144 25.6894 53.6667 25.3383V25.3383Z" fill="#125070"/>
                                            <path d="M57.5003 21.0834C62.7931 21.0834 67.0837 16.7927 67.0837 11.5C67.0837 6.20729 62.7931 1.91669 57.5003 1.91669C52.2076 1.91669 47.917 6.20729 47.917 11.5C47.917 16.7927 52.2076 21.0834 57.5003 21.0834Z" fill="#125070"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1034_7">
                                                <rect width="69" height="69" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div class="invoice-sidebar-content">
                                        <span class="confirmed-order">. 
                                            {{allTransactionsInfo.orderStatus == 'Done'?('Done'):(null)}}
                                            {{allTransactionsInfo.orderStatus == 'NULL' || allTransactionsInfo.orderStatus == null ?('Pending'):(null)}}
                                            {{allTransactionsInfo.orderStatus == 'CANCELED'?('Cancelled'):(null)}}
                                        </span>
                                        <h2>#{{allTransactionsInfo.orderNumber}}</h2>
                                        <h3>Price: RWF {{this.formatNumberWithCommas(allTransactionsInfo.orderTotal)}}</h3>
                                        <p>Delivery Address: {{allTransactionsInfo.orderAddress}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>     
                    <div class="invoice-content" v-if="loadingTransactionDetail">
                        <div class="spinner-border" role="status" style="margin-left: 50%;">
                          <span class="sr-only">Loading...</span>
                        </div> 
                    </div>
                    <div class="invoice-content" v-else>
                        <div class="transation">
                            <div>
                                <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.4125 22.3125C26.1375 21.9938 24.8625 21.3562 23.9062 20.4C22.95 20.0812 22.6313 19.125 22.6313 18.4875C22.6313 17.85 22.95 16.8937 23.5875 16.575C24.5437 15.9375 25.5 15.3 26.4563 15.6188C28.3688 15.6188 29.9625 16.575 30.9187 17.85L33.7875 14.025C32.8313 13.0688 31.875 12.4312 30.9187 11.7937C29.9625 11.1562 28.6875 10.8375 27.4125 10.8375V6.375H23.5875V10.8375C21.9937 11.1563 20.4 12.1125 19.125 13.3875C17.85 14.9812 16.8937 16.8937 17.2125 18.8062C17.2125 20.7187 17.85 22.6313 19.125 23.9062C20.7188 25.5 22.95 26.4563 24.8625 27.4125C25.8188 27.7313 27.0938 28.3688 28.05 29.0063C28.6875 29.6438 29.0063 30.6 29.0063 31.5562C29.0063 32.5125 28.6875 33.4687 28.05 34.425C27.0938 35.3812 25.8188 35.7 24.8625 35.7C23.5875 35.7 21.9938 35.3812 21.0375 34.425C20.0813 33.7875 19.125 32.8313 18.4875 31.875L15.3 35.3813C16.2563 36.6563 17.2125 37.6125 18.4875 38.5688C20.0813 39.525 21.9937 40.4813 23.9062 40.4813V44.625H27.4125V39.8438C29.325 39.525 30.9188 38.5688 32.1938 37.2938C33.7875 35.7 34.7437 33.15 34.7437 30.9187C34.7437 29.0062 34.1063 26.775 32.5125 25.5C30.9188 23.9062 29.325 22.95 27.4125 22.3125ZM25.5 0C11.475 0 0 11.475 0 25.5C0 39.525 11.475 51 25.5 51C39.525 51 51 39.525 51 25.5C51 11.475 39.525 0 25.5 0ZM25.5 47.4937C13.3875 47.4937 3.50625 37.6125 3.50625 25.5C3.50625 13.3875 13.3875 3.50625 25.5 3.50625C37.6125 3.50625 47.4937 13.3875 47.4937 25.5C47.4937 37.6125 37.6125 47.4937 25.5 47.4937Z" fill="#125070"/>
                                </svg>   
                                <div>
                                    <h1>Total Order Amount</h1>
                                    <p>{{this.formatNumberWithCommas(notification.orderTotal)}} RWF</p>
                                </div>                                 
                            </div>
                            <div>
                                <svg width="39" height="52" viewBox="0 0 39 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_285_206)">
                                        <path d="M14.625 9.75H29.25V13H14.625V9.75Z" fill="#125070"/>
                                        <path d="M14.625 16.25H29.25V19.5H14.625V16.25Z" fill="#125070"/>
                                        <path d="M14.625 22.75H29.25V26H14.625V22.75Z" fill="#125070"/>
                                        <path d="M14.625 29.25H29.25V32.5H14.625V29.25Z" fill="#125070"/>
                                        <path d="M14.625 35.75H29.25V39H14.625V35.75Z" fill="#125070"/>
                                        <path d="M8.125 9.75H11.375V13H8.125V9.75Z" fill="#125070"/>
                                        <path d="M8.125 16.25H11.375V19.5H8.125V16.25Z" fill="#125070"/>
                                        <path d="M8.125 22.75H11.375V26H8.125V22.75Z" fill="#125070"/>
                                        <path d="M8.125 29.25H11.375V32.5H8.125V29.25Z" fill="#125070"/>
                                        <path d="M8.125 35.75H11.375V39H8.125V35.75Z" fill="#125070"/>
                                        <path d="M35.75 0H3.25C2.38805 0 1.5614 0.34241 0.951903 0.951903C0.34241 1.5614 0 2.38805 0 3.25V48.75C0 49.612 0.34241 50.4386 0.951903 51.0481C1.5614 51.6576 2.38805 52 3.25 52H35.75C36.612 52 37.4386 51.6576 38.0481 51.0481C38.6576 50.4386 39 49.612 39 48.75V3.25C39 2.38805 38.6576 1.5614 38.0481 0.951903C37.4386 0.34241 36.612 0 35.75 0ZM35.75 48.75H3.25V3.25H35.75V48.75Z" fill="#125070"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_285_206">
                                            <rect width="39" height="52" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>     
                                <div>
                                    <h1>Total Product Purchased</h1>
                                    <p>{{notification.allOrderedProducts.length}}</p>
                                    <span class="span1" onclick="openDetail()">More Details</span>
                                    <span class="span2 hide" onclick="closeDetail()">Hide Details</span>
                                </div>                                  
                            </div>
                        </div>
                        <div class="invoice-details">
                            <div class="invoice-details-header">
                                <div>
                                    <h1>Track my order</h1>
                                    <p>Order ID: #{{notification.orderNumber}}</p>
                                    <p>Delivery CODE: {{notification.orderSucessCode}}</p>
                                    <p> 
                                        {{notification.orderStatus == 'Done'?('Your order is Done'):(null)}}
                                        {{notification.orderStatus == 'NULL' || notification.orderStatus == null ?('Your order is being processed'):(null)}}
                                        {{notification.orderStatus == 'CANCELED'?('Your order has been Canceled'):(null)}}
                                    </p>
                                </div>
                                <div class="right-detail">
                                    <h1>Payments Information</h1>
                                    <p>
                                        <b>Status:</b>  
                                        <span> 
                                            {{notification.orderStatus == 'Done'?('Done'):(null)}}
                                            {{notification.orderStatus == 'NULL' || notification.orderStatus == null ?('Pending'):(null)}}
                                            {{notification.orderStatus == 'CANCELED'?('Cancelled'):(null)}}
                                        </span>
                                    </p>
                                    <p><b>Payment Time:</b> {{this.returnSentTime(Number(notification.orderTime))}}</p>
                                    <p><b>Payment Method:</b> {{notification.paymentType}}</p>
                                    <!-- <p><b>Number used to pay:</b> (+250) 783 123 134</p> -->
                                </div>
                            </div>



                            <div class="order-steps" v-if="notification.orderStatus == null || notification.orderStatus == 'NULL'">
                                <h1>Orders</h1>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="red"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="/frontend/assets/box.png" alt="">
                                        <div>
                                            <h1>Order Complete</h1>
                                            <p>Thank you for shopping with us</p>
                                        </div>
                                    </div>
                                    <span>......</span>
                                </div>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="red"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="frontend/assets/ship.png" alt="">
                                        <div>
                                            <h1>Delivering your order</h1>
                                            <p>Contact us in case of any issue</p>
                                        </div>
                                    </div>
                                    <span>......</span>
                                </div>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="red"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="frontend/assets/proces.png" alt="">
                                        <div>
                                            <h1>Processing your order</h1>
                                            <p>We are Preparing your order</p>
                                        </div>
                                    </div>
                                    <span>......</span>
                                </div>
                                <div class="last-step">
                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="white"/>
                                    </svg>                                                                                
                                    <div>
                                        <img src="frontend/assets/list-step.png" alt="">
                                        <div>
                                            <h1>Order Placed</h1>
                                            <p>We have received your order</p>
                                        </div>
                                    </div>
                                    <span>......</span>
                                </div>
                            </div>



                            <div class="order-steps" v-if="notification.orderStatus == 'Done'">
                                <h1>Orders</h1>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="white"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="frontend/assets/box.png" alt="">
                                        <div>
                                            <h1>Order Complete</h1>
                                            <p>Thank you for shopping with us</p>
                                        </div>
                                    </div>
                                    <span>{{this.returnSentTime(Number(notification.orderTime))}}</span>
                                </div>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="white"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="frontend/assets/ship.png" alt="">
                                        <div>
                                            <h1>Delivering your order</h1>
                                            <p>Contact us in case of any issue</p>
                                        </div>
                                    </div>
                                    <span>{{this.returnSentTime(Number(notification.assignedTime))}}</span>
                                </div>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="white"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="frontend/assets/proces.png" alt="">
                                        <div>
                                            <h1>Processing your order</h1>
                                            <p>We are Preparing your order</p>
                                        </div>
                                    </div>
                                    <span>{{this.returnSentTime(Number(notification.assignedTime))}}</span>
                                </div>
                                <div class="last-step">
                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="white"/>
                                    </svg>                                                                                
                                    <div>
                                        <img src="frontend/assets/list-step.png" alt="">
                                        <div>
                                            <h1>Order Placed</h1>
                                            <p>We have received your order</p>
                                        </div>
                                    </div>
                                    <span>{{this.returnSentTime(Number(notification.orderTime))}}</span>
                                </div>
                            </div>



                            <div class="order-steps" v-if="notification.orderStatus == 'CANCELED'">
                                <h1>Orders</h1>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="red"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="frontend/assets/box.png" alt="">
                                        <div>
                                            <h1>Order Complete</h1>
                                            <p>Thank you for shopping with us</p>
                                        </div>
                                    </div>
                                    <span>......</span>
                                </div>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="red"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="frontend/assets/ship.png" alt="">
                                        <div>
                                            <h1>Delivering your order</h1>
                                            <p>Contact us in case of any issue</p>
                                        </div>
                                    </div>
                                    <span>......</span>
                                </div>
                                <div>
                                    <svg width="42" height="119" viewBox="0 0 42 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="69" r="10" fill="#50C5DD"/>
                                        <circle cx="20.5" cy="94.5" r="7.5" fill="#50C5DD"/>
                                        <circle cx="21" cy="114" r="5" fill="#50C5DD"/>
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="red"/>
                                    </svg>                                                                               
                                    <div>
                                        <img src="frontend/assets/proces.png" alt="">
                                        <div>
                                            <h1>Processing your order</h1>
                                            <p>We are Preparing your order</p>
                                        </div>
                                    </div>
                                    <span>.......</span>
                                </div>
                                <div class="last-step">
                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21" cy="21" r="21" fill="#50C5DD"/>
                                        <path d="M29.375 10.6562L17.1687 24.0766L13.625 21.3859H11.6562L17.1687 30.3438L31.3438 10.6562H29.375Z" fill="white"/>
                                    </svg>                                                                                
                                    <div>
                                        <img src="frontend/assets/list-step.png" alt="">
                                        <div>
                                            <h1>Order Placed</h1>
                                            <p>We have received your order</p>
                                        </div>
                                    </div>
                                    <span>{{this.returnSentTime(Number(notification.orderTime))}}</span>
                                </div>
                            </div>


                            <div class="deliveryInfo">
                                <img src="frontend/assets/Home delivery.png" alt="">
                                <div>
                                    <h1>DELIVERY ADDRESS
                                        <br>SELECTED DELIVERY ADDRESS</h1>
                                    <p>{{notification.orderAddress}}</p>
                                </div>
                            </div>
                        </div>


                        <h1 class="relatedTitle hide">Purchased Item</h1>
                        <div class="relatedcontainer hide">
                                
                            <div v-for="(allOrderedProductsInfo, allOrderedProductsInfoIndex) in this.notification.allOrderedProducts" :key="allOrderedProductsInfoIndex">
                                    <img src="https://admin.emart.rw/img/1261652000542.png" alt="" @click="productDetails(allOrderedProductsInfo)">
                                    <h1 @click="productDetails(allOrderedProductsInfo)">{{allOrderedProductsInfo.productName}}</h1>
                                    <p @click="productDetails(allOrderedProductsInfo)">{{allOrderedProductsInfo.productQuantity}} item(s)</p>
                                    <span @click="productDetails(allOrderedProductsInfo)">RWF {{this.formatNumberWithCommas(allOrderedProductsInfo.totalPrice)}}</span>
                            </div>
                        </div>
                    </div>                                       
                </div>
                <div class="separated">
                </div>
            </div>
            
         <Footer/>
        </div>
    </section>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
import axios from 'axios'
export default {
    name: 'Invoice',
    components:{
        Header,Footer,Sidebar
    },
    
    data(){
        return {

            gettingcategories_loading:true,
            category_with_products:[],
            cartProductNumber:0,
            notification_loading:true,
            allTransactions:[],
            loadingTransactionDetail:true,
            notification:null,
            clientInfo:null,
            userInfo:null,

        }
    },

    mounted() {  
    document.title = "Invoice";  
    },

    created(){
        this.getCartProductNumber();
        // this.getNotification();
        this.getUserInfo();
    },

    methods:{


        productDetails(allOrderedProductsInfo){
            // console.log(item_product);
            let clean_product_name = allOrderedProductsInfo.productName.replaceAll("/","-");
            localStorage.setItem("ProductDetail", JSON.stringify(allOrderedProductsInfo));
            this.$router.push(`/Product/${clean_product_name}`)
            // alert('James');
        },
        getUserInfo(){
            let user = localStorage.getItem('@allClientInfo');
            let allClientInfo = JSON.parse(user);
            this.clientInfo = allClientInfo.client;
            this.userInfo = allClientInfo.user;
            // console.log(this.clientInfo)
            this.getNotification();
        },
        transactionDetail(allTransactionsInfo){
            this.loadingTransactionDetail = true;
            this.notification = allTransactionsInfo;
            this.loadingTransactionDetail = false;
            console.log(allTransactionsInfo);
            // alert('James');
        },
        getCartProductNumber(){
            let NumberProduct = localStorage.getItem('@cart');
            NumberProduct = JSON.parse(NumberProduct);
            if (NumberProduct == null) {}else{
                this.cartProductNumber = NumberProduct.length;
            }
            
        },
        getNotification(){
            axios.get('https://admin.emart.rw/api/my-trasactions/'+this.clientInfo.clientId)
            .then((result) => {
                if(result.data){
                    this.notification_loading = false;
                    this.allTransactions = result.data.allTransaction;
                    this.notification = result.data.allTransaction[0];
                    this.loadingTransactionDetail = false;

                }
            })
            .catch((error) => {
                //console.log(error);
                this.notification_loading = false;
            });

        },
        getTimeStamp(input){
            var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var parts = input.trim().split(' ');
            var date = parts[0].split('-');
            var monthNumber = months.indexOf(date[1])+1;
            var time = (parts[1] ? parts[1] : '00:00:00').split(':');
            // NOTE:: Month: 0 = January - 11 = December.
            var d = new Date(date[0],monthNumber-1,date[2],time[0],time[1],time[2]);
            return d.getTime() / 1000;
        },
        returnSentTime(Timestring){
            let sanitising = new Date(Timestring);
            if (this.areSameDate(Date.now(), sanitising) === 'true') {
                return this.tenths(sanitising.getHours()) + ':' + this.tenths(sanitising.getMinutes());
            } else if (this.areSameDate(Date.now(), sanitising) === 'yesterday') {
                return 'Yesterday, ' + this.tenths(sanitising.getHours()) + ':' + this.tenths(sanitising.getMinutes());
            } else if (this.areSameDate(Date.now(), sanitising) === 'thisweek') {
                return this.dayText(sanitising.getDay()) + ', ' + this.tenths(sanitising.getHours()) + ':' + this.tenths(sanitising.getMinutes());
            } else if (this.areSameDate(Date.now(), sanitising) === 'false') {
                return sanitising.getFullYear() + '/' + (sanitising.getMonth() + 1) + '/' + sanitising.getDate();
            }
        },

        tenths(hourmin){
            return hourmin < 10 ? (0 + '' + hourmin) : hourmin;
        },

        dayText(day){
            return day === 0 ? 'Sun' : day === 1 ? 'Mon' : day === 2 ? 'Tue' : day === 3 ? 'Wed' : day === 4 ? 'Thu' : day === 5 ? 'Fri' : 'Sat';
        },

        areSameDate(d1, d2){
            d1 = new Date(d1);
            d2 = new Date(d2);
            if (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()) {
                return 'true';
            } else if (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && (d1.getDate() - d2.getDate() === 1)) {
                return 'yesterday';
            } else if (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && (d1.getDate() - d2.getDate() < 8)) {
                return 'thisweek';
            } else {
                return 'false';
            }
        },

        formatNumberWithCommas(x){
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g , ",");
          // return x.replace(/B(?=(d{3})+(?!d))/g, ",");
        }
    },
}
</script>