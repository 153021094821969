<template>
   <Header :cartProductNumber="cartProductNumber" />

        <div class="popup" v-if="popUpShow">
            <p>{{popUpMessage}}</p>
            <div class="popupbtn2">
                <!-- <button class="btncanc">Cancel</button> -->
                <button class="btncon" @click="okFunction()">OK</button>
            </div>
        </div>
        <div class="popup" v-if="popUpShow2">
            <h1>ARE YOU SURE?</h1>
            <p>{{popUpMessage}}</p>
            <div class="popupbtn">
                <button class="btncanc" @click="NoDelete()">No</button>
                <button class="btncon" @click="YesDelete()">Yes</button>
            </div>
        </div>
        <div class="popup" v-if="popUpShow3">
            <h1>ARE YOU SURE?</h1>
            <p>{{popUpMessage}}</p>
            <div class="popupbtn">
                <button class="btncanc" @click="emptyCartNo()">No</button>
                <button class="btncon" @click="emptyCartTrue()">Yes</button>
            </div>
        </div>

    <section class="body-section ca">
        <Sidebar />
        <div class="content-container">
            <div class="Main-container">
                <div class="cartContainer">
                    <div class="cartContent">
                        <h1>
                            <span>Items in your shopping cart</span>
                            <p @click="emptyCart()" class="clearcart" style="cursor:pointer">Empty cart</p>
                        </h1>
                        
                        <div class="card-container-scroll">
                            <div v-for="(cartInfo, cartIndex) in cart" :key="cartIndex" v-if="cart.length > 0">
                                <div class="cart-card">
                                    <div>
                                        <img :src="'https://admin.emart.rw/img/'+cartInfo.productImage1" alt="">
                                    </div>
                                    <div class="cartcontentcontainertext">
                                        <h2>{{cartInfo.productName}}</h2>
                                        <h3>{{cartInfo.productCategory}}</h3>
                                        <p>RWF {{this.formatNumberWithCommas(cartInfo.unityPrice)}}</p>
                                    </div>
                                    <div class="cards">
                                        <div class="quantity">
                                            <button @click="reduceQuantity(cartInfo,cartIndex)">
                                                <svg width="22" height="4" viewBox="0 0 22 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.5469 0.886719H0.453125C0.324219 0.886719 0.21875 0.992188 0.21875 1.12109V2.87891C0.21875 3.00781 0.324219 3.11328 0.453125 3.11328H21.5469C21.6758 3.11328 21.7812 3.00781 21.7812 2.87891V1.12109C21.7812 0.992188 21.6758 0.886719 21.5469 0.886719Z" fill="white"/>
                                                </svg>                                
                                            </button>
                                            <input type="text" v-model="cartInfo.unitQuantity" disabled>
                                            <button @click="addQuantity(cartInfo,cartIndex)">
                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.5 9.5H1.5M10.5 18.5V9.5V18.5ZM10.5 9.5V0.5V9.5ZM10.5 9.5H19.5H10.5Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
                                                </svg>                                    
                                            </button>
                                        </div>
                                        <a href="#" class="removecart" @click="deleteProduct(cartInfo,cartIndex)">
                                            <img src="frontend/assets/cart dele.png" alt="">&nbsp;                                                             
                                            <span>Remove</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div v-else>
                                <p class="noitem">No Products available in cart</p>
                            </div>
                        </div>
                        <div class="checkout-section">
                            <div @click="moveToCheckOut()">
                                <span>
                                    Continue to checkout &nbsp;
                                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.2956 0.201202L0.485596 1.0112L6.27035 6.7937L0.485596 12.5762L1.2956 13.3862L7.4831 7.1987L7.86897 6.7937L7.48253 6.3887L1.29503 0.201202H1.2956Z" fill="white"/>
                                    </svg>
                                </span>
                                <span><b>RWF {{this.formatNumberWithCommas(cartTotalPrice)}}</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </section>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
    name: 'Cart',
    components:{
        Header,Sidebar,Footer
    },
    mounted() {  
        document.title = "Cart";  
    },

    
    data(){
        return {
            cart : [],
            cartProductNumber:0,
            cartLength:0,
            cartTotalPrice:0,
            userLogin:false,
            popUpShow:false,
            popUpShow2:false,
            popUpShow3:false,
            popUpShow2Data:null,
            popUpMessage:'',
        }
    },
    created() {
        this.checkUser();
        this.getCartInformation();
        this.getCartProductNumber();
    },
    methods:{
        checkUser(){            
            let userInfo = localStorage.getItem('@allClientInfo');
            if (userInfo != null) {
                this.userLogin = true;
            }
        },
        getCartProductNumber(){
            let NumberProduct = localStorage.getItem('@cart');
            NumberProduct = JSON.parse(NumberProduct);
            if (NumberProduct == null) {}else{
                this.cartProductNumber = NumberProduct.length;
            }
            
        },
        addQuantity(cartInfo,cartIndex){
            let cartProducts = this.cart;
            let unitQuantity = cartProducts[cartIndex].unitQuantity;
            if (unitQuantity > cartProducts[cartIndex].productRemainingQuantity) {
                
                this.popUpMessage = 'You have reached the maximum quantiy in stock';
                this.popUpShow = true;
                // alert('You have reached the maximum quantiy in stock');
                return false;
            }
            cartProducts[cartIndex].unitQuantity = unitQuantity + 1;
            this.cart = cartProducts;
            localStorage.setItem("@cart", JSON.stringify(cartProducts));
            this.checkCartTotal();

        },
        reduceQuantity(cartInfo,cartIndex){
            let cartProducts = this.cart;
            let unitQuantity = cartProducts[cartIndex].unitQuantity;
            if (unitQuantity < 2) {
                // let option = confirm("Dear Client,\nAre you sure you want to delete this product from cart.");
                
                this.popUpMessage = 'Are you sure you want to delete this product from cart.';
                this.popUpShow2 = true;
                let popUpShow2DataInfo = {
                    'cartInfo':cartInfo,
                    'cartIndex':cartIndex,
                }
                this.popUpShow2Data = popUpShow2DataInfo;
                return false;
                // if (option == true) {
                //     this.removeProduct(cartInfo,cartIndex);
                //     return false
                // }else{
                //     return false;
                // }
            }
            cartProducts[cartIndex].unitQuantity = unitQuantity - 1;
            this.cart = cartProducts;
            localStorage.setItem("@cart", JSON.stringify(cartProducts));
            this.checkCartTotal();

        },
        NoDelete(){
            this.popUpShow2 = false;
        },
        YesDelete(){
            this.removeProduct(this.popUpShow2Data.cartInfo,this.popUpShow2Data.cartIndex);
            this.popUpShow2 = false;
        },

        okFunction(){
            this.popUpShow = false;
        },
        emptyCart(){
            this.popUpShow3 = true;
            this.popUpMessage = 'Are you sure you want to remove all products from your cart.';
        },

        emptyCartNo(){
            this.popUpShow3 = false;
        },
        emptyCartTrue(){
            this.popUpShow3 = false;
            localStorage.removeItem('@cart');
            this.cart = [];
            this.getCartProductNumber();
            this.checkCartTotal();
        },
        removeProduct(cartInfo,cartIndex){

            let cart = localStorage.getItem('@cart');
            let cartProducts = JSON.parse(cart);
            cartProducts.splice(cartIndex,1);
            this.cart = cartProducts;
            localStorage.setItem("@cart", JSON.stringify(cartProducts));
            this.getCartProductNumber();
            this.checkCartTotal();


            // const index = cartProducts.findIndex( (element) => element.productId === cartInfo.productId);
            // if (index < 0) {
            //     alert("Dear Client,\nThe product you product you trying to remove in cart doesn't exist.");
            // }else{
            //     cartProducts.splice(index,1);
            //     this.cart = cartProducts;
            //     localStorage.setItem("@cart", JSON.stringify(cartProducts));
            //     this.getCartProductNumber();
            // }
            // this.checkCartTotal();
        },
        deleteProduct(cartInfo,cartIndex){
            this.popUpMessage = 'Are you sure you want to delete this product from cart.';
            this.popUpShow2 = true;
            let popUpShow2DataInfo = {
                'cartInfo':cartInfo,
                'cartIndex':cartIndex,
            }
            this.popUpShow2Data = popUpShow2DataInfo;
            return false;
        },
        getCartInformation(){
            let cartInfo = localStorage.getItem('@cart');
            if (cartInfo == null) {
                this.cart = [];
                this.cartLength = [];
            }else{
                this.cart = JSON.parse(cartInfo);
                this.cartLength = JSON.parse(cartInfo).length;
            }
            this.checkCartTotal();
        },
        checkCartTotal(){
            if (this.cart.length == 0) {
                this.cartTotalPrice = 0;
            }else{
                let cartTotal = 0;
                this.cart.map((item,index)=>{cartTotal = cartTotal + (Number(item.unityPrice)*Number(item.unitQuantity))});
                this.cartTotalPrice = cartTotal;
            }
        },
        formatNumberWithCommas(x) {
              return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g , ",");
              // return x.replace(/B(?=(d{3})+(?!d))/g, ",");
        },

        moveToCheckOut(){
            if (this.cart.length > 0) {
                if (this.userLogin) {
                    this.$router.push(`/Checkout`) 
                }else{
                    let data = {
                        'page':'Cart',
                    };
                    localStorage.setItem("@previousPage", JSON.stringify(data));
                    this.$router.push(`/Login`) 
                }
            }else{
                this.popUpShow = true;
                this.popUpMessage = 'Dear Client,\nYour cart is empty. Please add some products in cart to proceed'; 
            }
        }
    } 
}

</script>