<template>
        
        <div class="sidebar-menu">
            <div>
            <ul class="ul">
                <li :class="route == 'Home' ? 'active' : 'inactive'">
                    <router-link to="/">
                        <hr>                  
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.4267 16.7676L17.7503 3.10117C17.6519 3.0025 17.5349 2.92423 17.4061 2.87082C17.2774 2.81741 17.1394 2.78992 17 2.78992C16.8606 2.78992 16.7225 2.81741 16.5938 2.87082C16.465 2.92423 16.348 3.0025 16.2496 3.10117L2.57319 16.7676C2.17476 17.166 1.94897 17.7072 1.94897 18.2717C1.94897 19.4437 2.9019 20.3967 4.07398 20.3967H5.51499V30.1484C5.51499 30.7361 5.9898 31.2109 6.57749 31.2109H14.875V23.7734H18.5937V31.2109H27.4224C28.0101 31.2109 28.4849 30.7361 28.4849 30.1484V20.3967H29.9259C30.4904 20.3967 31.0316 20.1742 31.43 19.7725C32.2568 18.9424 32.2568 17.5977 31.4267 16.7676V16.7676Z" fill="#545454"/>
                        </svg>  
                        <span>Home</span>    
                    </router-link>
                </li>
                <li :class="route == 'Notifications' ? 'active' : 'inactive' && route == 'notificationDetails' ? 'active' : 'inactive' ">
                    <router-link to="/Notifications">
                        <hr> 
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26 16.586V14H24V17C24.0001 17.2652 24.1054 17.5195 24.293 17.707L27 20.414V22H5V20.414L7.707 17.707C7.89455 17.5195 7.99994 17.2652 8 17V13C7.99721 11.5948 8.3653 10.2137 9.06709 8.99632C9.76887 7.7789 10.7795 6.76823 11.9969 6.06641C13.2143 5.36458 14.5953 4.99644 16.0005 4.99918C17.4058 5.00192 18.7853 5.37543 20 6.082V3.847C19.0481 3.42552 18.0356 3.15688 17 3.051V1H15V3.05C12.5346 3.30093 10.2498 4.45712 8.58737 6.29498C6.92498 8.13285 6.0031 10.5218 6 13V16.586L3.293 19.293C3.10545 19.4805 3.00006 19.7348 3 20V23C3 23.2652 3.10536 23.5196 3.29289 23.7071C3.48043 23.8946 3.73478 24 4 24H11V25C11 26.3261 11.5268 27.5979 12.4645 28.5355C13.4021 29.4732 14.6739 30 16 30C17.3261 30 18.5979 29.4732 19.5355 28.5355C20.4732 27.5979 21 26.3261 21 25V24H28C28.2652 24 28.5196 23.8946 28.7071 23.7071C28.8946 23.5196 29 23.2652 29 23V20C28.9999 19.7348 28.8946 19.4805 28.707 19.293L26 16.586ZM19 25C19 25.7956 18.6839 26.5587 18.1213 27.1213C17.5587 27.6839 16.7956 28 16 28C15.2044 28 14.4413 27.6839 13.8787 27.1213C13.3161 26.5587 13 25.7956 13 25V24H19V25Z" fill="#565656"/>
                            <path d="M26 12C28.2091 12 30 10.2091 30 8C30 5.79086 28.2091 4 26 4C23.7909 4 22 5.79086 22 8C22 10.2091 23.7909 12 26 12Z" fill="#125070"/>
                        </svg>      
                        <span>Notifications</span>                      
                    </router-link>
                </li>
<!--                 <li :class="route == 'Wish' ? 'active' : 'inactive'">
                    <router-link to="/Wish">
                        <hr> 
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_140_25)">
                                <path d="M13.5001 4.63724L12.2901 3.39355C9.45007 0.474178 4.24244 1.48162 2.36257 5.15193C1.48 6.87824 1.28088 9.37068 2.89244 12.5516C4.44494 15.6144 7.67482 19.2831 13.5001 23.2791C19.3253 19.2831 22.5535 15.6144 24.1077 12.5516C25.7193 9.36899 25.5218 6.87824 24.6376 5.15193C22.7577 1.48162 17.5501 0.47249 14.71 3.39187L13.5001 4.63724ZM13.5001 25.3125C-12.3744 8.21474 5.53338 -5.13001 13.2031 1.9288C13.3043 2.02162 13.4039 2.1178 13.5001 2.21737C13.5953 2.11789 13.6944 2.02219 13.7971 1.93049C21.4651 -5.13338 39.3745 8.21305 13.5001 25.3125Z" fill="#565656"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_140_25">
                                    <rect width="27" height="27" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>      
                        <span>Wishlist</span>                      
                    </router-link>
                </li> -->
                <li :class="route == 'Categories' ? 'active' : 'inactive'">
                    <router-link to="/Categories">
                        <hr> 
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="8" height="8" stroke="#5E5E5E"/>
                            <rect x="0.5" y="11.5" width="8" height="8" stroke="#5E5E5E"/>
                            <rect x="11.5" y="0.5" width="8" height="8" stroke="#5E5E5E"/>
                            <circle cx="15.5" cy="15.5" r="4" stroke="#5E5E5E"/>
                        </svg>     
                        <span>Categories</span>                      
                    </router-link>
                </li>
                
                <li :class="route == 'Profile' ? 'active' : 'inactive' && route == 'Invoice' ? 'active' : 'inactive'">
                    <router-link to="/Profile">
                        <hr> 
                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.49112 21.3828L8.26658 21.7132L8.5393 22.0052C10.2795 23.868 12.7515 25.0417 15.4999 25.0417C18.2484 25.0417 20.7203 23.868 22.4605 22.0052L22.7333 21.7132L22.5087 21.3828C21.2638 19.5508 19.1829 18.3222 16.7916 18.3222H14.2083C11.8183 18.3222 9.73606 19.5508 8.49112 21.3828ZM3.08325 15.5C3.08325 8.77402 8.77394 3.08333 15.4999 3.08333C22.2259 3.08333 27.9166 8.77402 27.9166 15.5C27.9166 22.226 22.2259 27.9167 15.4999 27.9167C8.77394 27.9167 3.08325 22.226 3.08325 15.5ZM19.8749 12.9167C19.8749 10.4084 18.0067 8.54167 15.4999 8.54167C12.9946 8.54167 11.1249 10.4083 11.1249 12.9167C11.1249 15.425 12.9946 17.2917 15.4999 17.2917C18.0067 17.2917 19.8749 15.4249 19.8749 12.9167Z" stroke="black"/>
                        </svg>     
                        <span>My Profile</span>                      
                    </router-link>
                </li>
                <li :class="route == 'About' ? 'active' : 'inactive'">
                    <router-link to="/About">
                        <hr>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM11.5 6C11.2348 6 10.9804 6.10536 10.7929 6.29289C10.6054 6.48043 10.5 6.73478 10.5 7C10.5 7.26522 10.6054 7.51957 10.7929 7.70711C10.9804 7.89464 11.2348 8 11.5 8H12C12.2652 8 12.5196 7.89464 12.7071 7.70711C12.8946 7.51957 13 7.26522 13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6H11.5ZM10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12H11V15H10C9.73478 15 9.48043 15.1054 9.29289 15.2929C9.10536 15.4804 9 15.7348 9 16C9 16.2652 9.10536 16.5196 9.29289 16.7071C9.48043 16.8946 9.73478 17 10 17H14C14.2652 17 14.5196 16.8946 14.7071 16.7071C14.8946 16.5196 15 16.2652 15 16C15 15.7348 14.8946 15.4804 14.7071 15.2929C14.5196 15.1054 14.2652 15 14 15H13V11C13 10.7348 12.8946 10.4804 12.7071 10.2929C12.5196 10.1054 12.2652 10 12 10H10Z" fill="#545454"/>
                    </svg>

                        <span>About us</span>
                    </router-link>
                </li>
                <li class="bottom-menu" v-if="userLogin">
                    <a @click="signout_function()">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 27.5C8.09625 27.5 2.5 21.9038 2.5 15C2.5 8.09625 8.09625 2.5 15 2.5C16.9408 2.49855 18.8552 2.94972 20.5912 3.81768C22.3271 4.68564 23.8367 5.94647 25 7.5H21.6125C20.169 6.2272 18.389 5.39795 16.4859 5.11175C14.5828 4.82556 12.6376 5.09457 10.8836 5.88652C9.12964 6.67847 7.64144 7.9597 6.59759 9.57649C5.55374 11.1933 4.99859 13.0769 4.99875 15.0014C4.99891 16.9259 5.55437 18.8094 6.59849 20.426C7.64261 22.0426 9.13102 23.3236 10.8851 24.1153C12.6392 24.9069 14.5845 25.1756 16.4875 24.8891C18.3906 24.6026 20.1705 23.773 21.6138 22.5H25.0012C23.8378 24.0537 22.328 25.3146 20.5919 26.1826C18.8557 27.0506 16.941 27.5017 15 27.5ZM23.75 20V16.25H13.75V13.75H23.75V10L30 15L23.75 20Z" fill="#565656"/>
                        </svg>        
                        <span>Sign out</span>                      
                    </a>
                </li>
                <li class="bottom-menu" v-if="!userLogin">
                    <a @click="sign_function()">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 12C9.14014 12 6 9.30845 6 6C6 2.69155 9.14014 0 13 0C16.8599 0 20 2.69155 20 6C20 9.30845 16.8599 12 13 12ZM13 2.41518C10.6939 2.41518 8.81771 4.02335 8.81771 6C8.81771 7.97665 10.6939 9.58482 13 9.58482C15.3061 9.58482 17.1823 7.97665 17.1823 6C17.1823 4.02335 15.3061 2.41518 13 2.41518Z" fill="#4C504E"/>
                            <path d="M2.49994 29.0495C1.66568 29.0495 0.98938 28.5444 0.98938 27.9213V19.9453C0.98938 16.3545 4.90087 13.4331 9.70878 13.4331H16.3669C19.3535 13.4331 22.1027 14.5629 23.7208 16.455C24.1886 17.0024 24.5425 17.5958 24.773 18.2186C24.9957 18.8188 24.524 19.4406 23.7199 19.6066C22.9171 19.7739 22.0837 19.4209 21.8615 18.8201C21.7109 18.4146 21.48 18.0274 21.174 17.6699C20.114 16.4299 18.3168 15.6898 16.3669 15.6898H9.70878C6.56681 15.6898 3.5 17.6534 3.5 20V27.9213C3.5 28.5444 3.33421 29.0495 2.49994 29.0495Z" fill="#4C504E"/>
                            <path d="M23.5757 29.0494C22.7415 29.0494 22.0652 28.5443 22.0652 27.9212V27.2005C22.0652 26.5774 22.7415 26.0723 23.5757 26.0723C24.41 26.0723 25.0863 26.5774 25.0863 27.2005V27.9212C25.0863 28.5443 24.4096 29.0494 23.5757 29.0494Z" fill="#4C504E"/>
                            <path d="M17.2714 22.3822L19.5239 20.256C19.8856 19.9147 20.4715 19.9147 20.8331 20.256C21.1947 20.5971 21.1947 21.1507 20.8331 21.4917L20.1612 22.126H29.0741C29.5855 22.126 30 22.5172 30 22.9999C30 23.4826 29.5855 23.8738 29.0741 23.8738H20.1609L20.8331 24.5083C21.1947 24.8497 21.1947 25.403 20.8331 25.7441C20.6522 25.9149 20.4154 26 20.1784 26C19.9414 26 19.7044 25.9146 19.5237 25.7441L17.2712 23.6177C16.9096 23.2766 16.9096 22.7232 17.2714 22.3822Z" fill="#4C504E"/>
                        </svg>
       
                        <span>Sign in</span>                      
                    </a>
                </li>
            </ul>
            </div>
        </div>
    
</template>


<script>
    export default{
        name: 'Sidebar',
        data(){
            return{
                user: '',
                route: '',
                userLogin:false,
            }
        },
        created(){
            // this.user = JSON.parse(localStorage.getItem('user'));
            this.route = this.$route.name;
            this.checkUser();

        },
        methods:{
            rou(link){
                return 'class="active"';
            },
            logout(){
                localStorage.removeItem('user');
                window.location.href = '/Login';
            },
            checkUser(){            
                let userInfo = localStorage.getItem('@allClientInfo');
                if (userInfo != null) {
                    this.userLogin = true;
                }
            },
            signout_function(){
                localStorage.removeItem('@allClientInfo');
                this.userLogin = false;
                window.location.href = '/Login';
            },
            sign_function(){
                localStorage.removeItem('@allClientInfo');
                this.userLogin = false;
                window.location.href = '/Login';
            },
        } 
    }
</script>