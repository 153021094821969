import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Pages/Home.vue';
import Product from '../views/Pages/Product.vue';
import Notifications from '../views/Pages/Notifications.vue';
import Wish from '../views/Pages/Wish.vue';
import About from '../views/Pages/About.vue';
import Categories from '../views/Pages/Categories.vue';
import Products from '../views/Pages/Products.vue';
import Login from '../views/Pages/Login.vue';
import Register from '../views/Pages/Register.vue';
import otp from '../views/Pages/otp.vue';
import Profile from '../views/Pages/Profile.vue';
import Cart from '../views/Pages/Cart.vue';
import Checkout from '../views/Pages/Checkout.vue';
import Invoice from '../views/Pages/Invoice.vue';
import notificationDetails from '../views/Pages/notificationDetails.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {

    name: '/Product',
    path: '/Product/:product_id',
    component: Product,
  },
  {
    path: '/Notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
        auth: true,
        
    },

  },
  {
    path: '/Login',
    name: 'Login',
    component: Login

  },
  {
    path: '/Register',
    name: 'Register',
    component: Register

  },
  {
    path: '/otp',
    name: 'otp',
    component: otp

  },
  {
    path: '/Wish',
    name: 'Wish',
    component: Wish

  },
  {
    path: '/About',
    name: 'About',
    component: About

  },
  {
    path: '/Categories',
    name: 'Categories',
    component: Categories

  },
  {
    name: '/Products',
    path: '/Products/:Category_id',
    component: Products,

  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    meta: {
        auth: true,
        
    },

  },
  {
    path: '/Cart',
    name: 'Cart',
    component: Cart

  },
  {
    path: '/Checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
        auth: true,
        
    },

  },
  {
    path: '/Invoice',
    name: 'Invoice',
    component: Invoice,
    meta: {
        auth: true,
        
    },

  },
  // {
  //   path: '/Invoice/:orderNumber',
  //   name: 'Invoice',
  //   component: Invoice

  // },
  {
    path: '/notificationDetails/:orderNumber',
    name: 'notificationDetails',
    component: notificationDetails,
    meta: {
        auth: true,
        
    },

  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
    const logginIn = localStorage.getItem("@allClientInfo");
    let loginStatus  = logginIn == null?(false):(true);
    // if (to.matched.some(record => record.name == 'Login') && logginIn) {
    //         window.location.href = '/dashboard';
    //         return;
    // }
    //console.log(to.fullPath);
    if (to.matched.some(record => record.meta.auth) && !loginStatus) {
          window.location.href = '/Login';
          return;
    }
    next();
});

export default router
